<template>
    <tr>
        <td class="TableOrderNumber"><span>{{this.ordine?.NumeroOrdine ?? "-"}}</span></td>
        <td class="TableOrderDate"><span>{{this.DataOrdine}}</span></td>
        <td class="TableAddress"><span>{{this.ordine?.DescrizioneDestinazione}}</span></td>
        <td class="TableOrderPrice"><span>€ {{this.TotaleOrdine}}</span></td>
        <td class="TableButton"><div class="Button Small"><router-link :to="{name:'SchedaOrdine', params: {IdOrdine: this.ordine?.NumeroOrdine  } }">Visualizza</router-link></div></td>
    </tr>
</template>

<script>

import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

export default {
    name: "HistoryTableRow",
    props:{
        ordine: Object
    },
    computed:{
        DataOrdine: function(){
            if(this.ordine != null && this.ordine.DataOrdine != null){
                let v = this.ordine.DataOrdine.split(' ')[0];
                return v;
            }else {
                return "-";
            }
        },
        TotaleOrdine: function(){
            if(this.ordine != null && this.ordine.DataOrdine != null){
                let v = parseFloat(this.ordine?.TotaleOrdine) + parseFloat(this.ordine?.SpeseSpedizione);
                return v;
            }else {
                return "-";
            }
        }
    }
}
</script>