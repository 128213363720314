<template>
    <FiltroStoricoOrdini
        @applyFilter="this.applyFilters($event)"
        @resetFilter="this.resetFilters"
    />

    <HistoryTable
        v-if="this.IsReady"
        :listaOrdini="this.listaRet"
    />
</template>

<script>

import HistoryTable from '@/components/Historytable.vue';
import FiltroStoricoOrdini from "@/components/Filters/FiltroStoricoOrdini.vue";
import {risorseMixin,apiMixin,dateMixin} from "@/mixin.js";

import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

export default {
    name: "StoricoOrdini",
    components: {
        HistoryTable,
        FiltroStoricoOrdini
    },
    data(){
        return{
            listaOrdini:[],
            listaOrdiniOriginale:[],
            IsReady: false,
            listaRet: []
        }
    },
    mixins:[risorseMixin,apiMixin,dateMixin],
    mounted:function(){
        this.GetOrdini();
    },
    methods:{
        applyFilters:function(filterData){
            this.listaOrdini = this.listaOrdiniOriginale;
            this.listaRet = [];
            this.listaRet = this.listaOrdini.filter(
                ordine =>{
                    let cond = true;
                    if(filterData.codArt != null && filterData.codArt != ""){
                        cond = cond && (ordine.RigheOrdine.some(o => o.CodiceArticolo.includes(filterData.codArt)) || (ordine.RigheOrdine.some(o => o.DescrizioneArticolo.toLowerCase().includes(filterData.codArt.toLowerCase()))));
                        }
                    if(filterData.DtDal != null && filterData.DtAl == null ){
                        let d = ordine.DataOrdine.split(' ')[0].split('/');
                        let date = new Date(d[1]+'/'+d[0]+'/'+d[2]);
                        let v = new Date(filterData.DtDal);
                        v.setHours(v.getHours() -1);
                        cond = cond && date >= v;
                    }
                    if(filterData.DtAl != null && filterData.DtDal == null ){
                        let d = ordine.DataOrdine.split(' ')[0].split('/');
                        let date = new Date(d[1]+'/'+d[0]+'/'+d[2]);
                        let v = new Date(filterData.DtDal);
                        v.setHours(v.getHours() -1);
                        cond = cond && date <= v;
                    } 
                    if(filterData.DtAl != null && filterData.DtDal != null ){
                        let d = ordine.DataOrdine.split(' ')[0].split('/');
                        let date = new Date(d[1]+'/'+d[0]+'/'+d[2]);
                        let v = new Date(filterData.DtDal);
                        let v2 = new Date(filterData.DtAl);
                        v.setHours(v.getHours() -1);
                        cond = cond && (date >= v && date <= v2);
                    } 

                    return cond;
                }
            );
        },
        resetFilters: function(){
            this.listaRet = this.listaOrdiniOriginale;
        },
        GetOrdini: function(){
            let shop = this.$store.getters.getShop;
            if(shop != null){
                this.doApiCall("get",shop+"/StoricoOrdini/Get", this.$store.getters.getToken).then(
                    (data) => {
                        if(data){
                            this.listaOrdini = data;
                            this.listaOrdiniOriginale = data;
                            if(this.listaOrdini.length > 0){
                                this.listaRet = this.listaOrdini;
                                this.IsReady = true;
                            }

                        }
                    }
                );
            }
        }
    }
}
</script>