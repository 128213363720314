<template>
    <table v-if="this.isReady" width="100%" border="0" cellspacing="0" cellpadding="0" summary="In questa tabella sono riassunti gli ultimi ordini di acquisto effettuati. Con la possibilità di accedere al dettaglio dell'ordine stesso.">
        <tbody>
            <tr class="TableHead">
                <th scope="col" class="TableOrderNumber">Ordine n°</th>
                <th scope="col" class="TableOrderDate">Data ordine</th>
                <th scope="col" class="TableAddress">Indirizzo di destinazione</th>
                <th scope="col" class="TableOrderPrice">Totale ordine</th>
                <th scope="col" class="TableButton">&nbsp;</th>
            </tr>
            <HistoryTableRow
                v-for="ordine in this.listaOrdini" 
                :key="ordine?.testata?.CodiceOrdine" 
                :ordine="ordine"
            />   
        </tbody>
    </table>
</template>

<script>
import {priceMixin, risorseMixin} from "@/mixin.js";
import HistoryTableRow from "@/components/HistoryTableRow.vue";

export default {
    name: "Historytable",
    props:{
        listaOrdini: Array
    },
    data(){
        return {
            isLoading: true,
            isReady: false
        }
    },
    mixins: [priceMixin, risorseMixin],
    watch:{
        listaOrdini: function(newVal){
            if(newVal != null && newVal.length > 0){
                this.isReady = true;
            }
        }
    },  
    components: {       
        HistoryTableRow       
    },
    mounted:function(){
        this.isReady = this.listaOrdini != null && this.listaOrdini.length > 0;
    }
}
</script>

<style>
.loaderOrdersList{
    padding-top: 0 !important;    
}

</style>