<template>
    <h3 class="UserDataTableIndex"><span>Storico acquisti</span><a class="FilterPanelOpenButton" onClick="orderhistoryfilter()"><img src="/images/FilterPanelButtonIcon.png" srcset="/images/FilterPanelButtonIconRetina.png 2x" alt="Filtra elenco" /><i>Filtri</i></a></h3>
    <div class="FilterPanel">
        <form class="Form">
            <fieldset>
                <legend>Filtra elenco per:</legend>
                <div class="FormRow">
                    <div class="FormBox25">
                        <label>Cerca articolo</label>
                        <input type="text" v-model="this.codArt" @keypress.enter="this.applyFilter">
                    </div>
                    <div class="FormBox25">
                        <label>Periodo dal</label>
                        <input type="date" v-model="this.dtDal" @keypress.enter="this.applyFilter">
                    </div>
                    <div class="FormBox25">
                        <label>Periodo al</label>
                        <input type="date" v-model="this.dtAl" @keypress.enter="this.applyFilter">
                    </div>
                    <div class="FormBox25">
                        <label>&nbsp;</label>
                        <div class="Button Small FilterButton">
                            <input type="button" value="Filtra" @click="this.applyFilter">
                            <input type="button" value="Rimuovi filtri" @click="this.resetFilter">
                        </div>
                    </div>
                </div>
            </fieldset>
        </form>
    </div>
</template>
<script>
export default {
    name:"FiltroStoricoOrdini",
    data(){
        return {
            codArt:"",
            dtDal: null,
            dtAl: null
        }
    },
    emits:['resetFilter','applyFilter'],
    methods:{
        resetFilter:function(){
            this.codArt="",
            this.dtDal = null;
            this.dtAl = null;     
            this.$emit('resetFilter');   
        },
        applyFilter: function(){
            this.$emit('applyFilter',this.preparePayloadFilter());
        },
        preparePayloadFilter: function(){
            let payload = {
                codArt: this.codArt,
                DtDal: this.dtDal,
                DtAl: this.dtAl
            };
            return payload;
        }
    }
}
</script>